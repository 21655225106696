function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useState } from "react";
import { breakpointMap } from "../theme/base";
import { useIsomorphicEffect } from "./useIsomorphicEffect";
/**
 * Can't use the media queries from "base.mediaQueries" because of how matchMedia works
 * In order for the listener to trigger we need have the media query with a range, e.g.
 * (min-width: 370px) and (max-width: 576px)
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
 */ var mediaQueries = function() {
    var prevMinWidth = 0;
    return Object.keys(breakpointMap).reduce(function(accum, size, index) {
        // Largest size is just a min-width of second highest max-width
        if (index === Object.keys(breakpointMap).length - 1) {
            return _objectSpread({}, accum, _defineProperty({}, size, "(min-width: ".concat(prevMinWidth, "px)")));
        }
        var minWidth = prevMinWidth;
        var breakpoint = breakpointMap[size];
        // Min width for next iteration
        prevMinWidth = breakpoint + 1;
        return _objectSpread({}, accum, _defineProperty({}, size, "(min-width: ".concat(minWidth, "px) and (max-width: ").concat(breakpoint - 1, "px)")));
    }, {});
}();
var getKey = function(size) {
    return "is".concat(size.charAt(0).toUpperCase()).concat(size.slice(1));
};
var getState = function() {
    var s = Object.keys(mediaQueries).reduce(function(accum, size) {
        var key = getKey(size);
        if ("object" === "undefined") {
            return _objectSpread({}, accum, _defineProperty({}, key, false));
        }
        var mql = typeof (window === null || window === void 0 ? void 0 : window.matchMedia) === "function" ? window.matchMedia(mediaQueries[size]) : null;
        var ref;
        return _objectSpread({}, accum, _defineProperty({}, key, (ref = mql === null || mql === void 0 ? void 0 : mql.matches) !== null && ref !== void 0 ? ref : false));
    }, {});
    return s;
};
var useMatchBreakpoints = function() {
    var ref = useState(function() {
        return getState();
    }), state = ref[0], setState = ref[1];
    useIsomorphicEffect(function() {
        // Create listeners for each media query returning a function to unsubscribe
        var handlers = Object.keys(mediaQueries).map(function(size) {
            var mql;
            var handler;
            if (typeof (window === null || window === void 0 ? void 0 : window.matchMedia) === "function") {
                mql = window.matchMedia(mediaQueries[size]);
                handler = function(matchMediaQuery) {
                    var key = getKey(size);
                    setState(function(prevState) {
                        return _objectSpread({}, prevState, _defineProperty({}, key, matchMediaQuery.matches));
                    });
                };
                // Safari < 14 fix
                if (mql.addEventListener) {
                    mql.addEventListener("change", handler);
                }
            }
            return function() {
                // Safari < 14 fix
                if (mql === null || mql === void 0 ? void 0 : mql.removeEventListener) {
                    mql.removeEventListener("change", handler);
                }
            };
        });
        setState(getState());
        return function() {
            handlers.forEach(function(unsubscribe) {
                unsubscribe();
            });
        };
    }, []);
    return _objectSpread({}, state, {
        isMobile: state.isXs || state.isSm,
        isTablet: state.isMd || state.isLg,
        isDesktop: state.isXl || state.isXxl
    });
};
export default useMatchBreakpoints;
